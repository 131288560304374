import { useEffect, useState } from "react";
import { Text, Box } from "@chakra-ui/react";
const Clock = () => {
  const navBarDateFormat = (date) => {
    // 13:00 Thu Mar 17 2022
    const thedate =
      new Date(date).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }) +
      " " +
      new Date(date).toLocaleDateString("en-GB", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
    return thedate;
  };
  const [theTime, setTime] = useState(navBarDateFormat(new Date()));

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(navBarDateFormat(new Date()));
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  return (
    <Text fontSize="20" margin="auto" pr="1" color="white">
      {theTime}
    </Text>
  );
};

export default Clock;
