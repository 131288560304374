import { Image, useMediaQuery } from "@chakra-ui/react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import wreck1 from "../assets/windy_wreck_1.jpg";
import wreck2 from "../assets/windy_wreck_2.jpg";
import wreck3 from "../assets/windy_wreck_3.jpg";
import { useEffect } from "react";

const Carousel = () => {
  const [isDesktop] = useMediaQuery("(min-width: 701px)");

  // Remove Next/Prev Carousel Buttons on Mobile, Restore Default CSS on Desktop screens
  useEffect(() => {
    var arrowPrev = document.querySelector(".swiper-button-prev");
    var arrowNext = document.querySelector(".swiper-button-next");
    // var bullet = document.querySelector(".swiper-pagination-bullet");
    if (isDesktop) {
      // Desktop
      arrowPrev.style.display = null;
      arrowNext.style.display = null;
    } else {
      // Mobile
      arrowPrev.style.display = "none";
      arrowNext.style.display = "none";
    }
  });

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      loop={true}
      spaceBetween={0}
      slidesPerView={1}
      onSlideChange={() => {}}
      // navigation={{
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // }}
      navigation
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <Image
          className={
            isDesktop ? "banner-carousel-desktop" : "banner-carousel-mobile"
          }
          fit="cover"
          src={wreck3}
        ></Image>
      </SwiperSlide>
      <SwiperSlide>
        <Image
          className={
            isDesktop ? "banner-carousel-desktop" : "banner-carousel-mobile"
          }
          fit="cover"
          src={wreck2}
        ></Image>
      </SwiperSlide>
      <SwiperSlide>
        <Image
          className={
            isDesktop ? "banner-carousel-desktop" : "banner-carousel-mobile"
          }
          fit="cover"
          src={wreck1}
        ></Image>
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
