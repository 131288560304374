import {
  Box,
  Text,
  Link,
  Flex,
  Center,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Container,
  Image,
} from "@chakra-ui/react";
import React from "react";
import Contact from "./Contact";
import canada from "../assets/canada.png";

const Footer = () => {
  // const color = useColorModeValue('white', 'gray.800')
  const [closed, setClosed] = React.useState(true);
  // console.log("CLOSED", closed);
  const disclaimer = React.useRef(null);

  const handleChange = () => {
    if (closed === true) {
      setTimeout(() => {
        // console.log("Fire");
        disclaimer.current.scrollIntoView(true, { behavior: "smooth" });
      }, 400);
      setClosed(false);
    } else {
      setClosed(true);
    }
  };

  return (
    <>
      <Box w="100%" h="20px" bgGradient={["linear(to-b, blue.700, black)"]} />
      <Contact />

      <Box bg="black" fontSize="xl" maxW="100%">
        <Accordion allowToggle py="7">
          <Container maxW="5xl">
            <AccordionItem rounded="xl">
              <h2>
                <AccordionButton
                  bg="purple"
                  rounded="xl"
                  onClick={() => handleChange()}
                  className="disclaim"
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    ref={disclaimer}
                    className="anchor"
                  >
                    <Center>
                      <Text fontSize="3xl" color="white">
                        Disclaimer!
                      </Text>
                    </Center>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="orange" color="black" rounded="xl">
                The weather information contained on this website is for
                informational purposes only and is not to be relied upon for
                navigation or recreation. This data is aggregated and presented
                in good faith, however the user accepts all liability resulting
                from decisions based on this information. This website is
                provided without any warranty or guarantee of accuracy,
                reliability and availability. The original data sources for the
                measured and forecasted data on this website are independently
                provided by Environment Canada, the National Oceanic and
                Atmospheric Administration, FlowWorks and the City of Surrey,
                BC.
              </AccordionPanel>
            </AccordionItem>
          </Container>
        </Accordion>

        <Container bg="black" maxW="5xl">
          <Text align="justify" fontSize="15px" color="white">
            <Image
              float="left"
              w="70px"
              h="40px"
              src={canada}
              mr="1"
              mt="1"
              rounded="sm"
            ></Image>
            The data displayed on this website is sourced from Environment and
            Climate Change Canada, The National Oceanic and Atmospheric
            Administration, FlowWorks, and the City of Surrey, BC.
          </Text>
          <Center>
            <Text color="white" fontSize="12px" pt="1">
              <i>
                Note: Data is intended to be displayed within the Canada/Pacific
                Timezone.
              </i>
            </Text>
          </Center>
        </Container>

        <Center>
          <Box mt="4" bg="blue.600" w="1500px" h="2px" />
        </Center>
        <Box fontSize="lg" py="2" bg="#0f0f0f">
          <Center>
            <Flex>
              <Text color="white">© 2024 Copyright:</Text>
              <Link color="white" href="https://604windswell.ca">
                &nbsp;604windswell.ca
              </Link>
            </Flex>
          </Center>
        </Box>
      </Box>

      {/* <div ref={bottom}></div> */}
    </>
  );
};

export default Footer;
