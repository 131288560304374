import {
  Box,
  Flex,
  Heading,
  Container,
  Center,
  IconButton,
  Text,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  AccordionButton,
  Spacer,
  useMediaQuery,
  useColorMode,
} from "@chakra-ui/react";
import { IoMailOutline, IoLocationOutline } from "react-icons/io5";
import React from "react";

const Contact = () => {
  const [isDesktop] = useMediaQuery("(min-width: 701px)");
  const [copied, setCopied] = React.useState(false);
  const { colorMode } = useColorMode();
  // console.log("isDesktop????", isDesktop);
  return (
    <>
      <Box bg="black" fontSize="18px" pt="4">
        <Accordion allowToggle py="7">
          <Container maxW="5xl">
            <AccordionItem rounded="xl">
              <h2>
                <AccordionButton
                  bg="rgb(91, 107, 255)"
                  rounded="xl"
                  className="accord"
                >
                  <Box flex="1" textAlign="left">
                    <Center>
                      <Heading color="black" mr="4">
                        Contact
                      </Heading>

                      <IoMailOutline color="black" size="28px" />
                    </Center>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="gray.800" color="white" rounded="xl">
                <Center my="4">
                  <Text>
                    Feature requests, content suggestions, or other inquires,
                    feel free to get in touch.
                  </Text>
                </Center>
                <Center>
                  <Box w="90%" h="3px" bg="blue.600" />
                </Center>
                <Flex my="3" flexWrap="wrap" gap="5">
                  {isDesktop ? <Spacer /> : ""}
                  <Flex gap="2">
                    <IconButton
                      size="lg"
                      color={colorMode === "light" ? "black" : "white"}
                      icon={<IoMailOutline />}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "windwaves604@outlook.com"
                        );
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 3000);
                      }}
                    ></IconButton>
                    <Text margin="auto">
                      windwaves604@
                      <wbr />
                      outlook.com
                    </Text>
                  </Flex>
                  {isDesktop ? <Spacer /> : ""}
                  <Flex gap="2">
                    <IconButton
                      size="lg"
                      color={colorMode === "light" ? "black" : "white"}
                      icon={<IoLocationOutline />}
                    ></IconButton>
                    <Text margin="auto">B.C.</Text>
                  </Flex>
                  {isDesktop ? <Spacer /> : ""}
                </Flex>
                <Center>
                  <Text className={copied ? "fade-in" : "fade-out"}>
                    <b>{copied ? "Copied to clipboard" : ""}</b>
                  </Text>
                </Center>
              </AccordionPanel>
            </AccordionItem>
          </Container>
        </Accordion>
      </Box>
    </>
  );
};

export default Contact;
