// import React from "react";
import { useState, createContext, useRef } from "react";

export const ScrollerContext = createContext();

const ScrollerContextProvider = (props) => {
  //scroller => "current","tides","seatemp","forecast"
  const [scroller, setScroller] = useState(null);
  const currentLoadRef = useRef(null);
  const tideLoadRef = useRef(null);
  const tempLoadRef = useRef(null);

  return (
    <ScrollerContext.Provider
      value={{
        scroller,
        setScroller,
        currentLoadRef,
        tideLoadRef,
        tempLoadRef,
      }}
    >
      {props.children}
    </ScrollerContext.Provider>
  );
};

export default ScrollerContextProvider;
