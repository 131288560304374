import {
  Heading,
  Container,
  useColorMode,
  IconButton,
  Flex,
  Text,
  Box,
  useMediaQuery,
  Spacer,
} from "@chakra-ui/react";
import { IoSunny, IoMoon } from "react-icons/io5";
import "./Base.css";
import "./burger.css";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import Carousel from "../components/Carousel";
import "fix-date";
import Clock from "../components/Clock";

const Base = (props) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isMobile] = useMediaQuery("(max-width: 701px)");
  return (
    <div id="outer-container">
      <SideBar />
      <Box>
        <main id="page-wrap">
          {/* NAV */}
          <Box w="75px" h="67px" bg="black" className="sticky-corner"></Box>
          <Box
            w="100%"
            boxShadow="xl"
            borderBottom="2px"
            borderBottomColor="blue.500"
            p="3"
            className={`${isMobile ? "is-sticky-burger" : ""} nav-gradient`}
          >
            <Container maxW="8xl">
              <Flex gap="2">
                <Heading mr="8">
                  {/* #e5c9a2 */}
                  <Text
                    color="rgb(51, 153, 255)"
                    ml="49px"
                    fontSize={isMobile ? "3xl" : "4xl"}
                    display="inline"
                  >
                    604windswell
                  </Text>
                </Heading>
                {!isMobile && (
                  <>
                    <Spacer />
                    <Clock />
                  </>
                )}
                <IconButton
                  onClick={toggleColorMode}
                  bg={colorMode === "light" ? "blue.300" : "gray.200"}
                  color={colorMode === "light" ? "black" : "black"}
                  size={"md"}
                  icon={colorMode === "light" ? <IoMoon /> : <IoSunny />}
                  aria-label={"Toggle Color Mode"}
                  margin="auto"
                />
              </Flex>
            </Container>
          </Box>
          <Box w="100%" color="white" mt={isMobile ? "67px" : "0px"}>
            <Carousel />
          </Box>
          {props.children}
          <Footer />
        </main>
      </Box>
    </div>
  );
};

export default Base;
