import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import Base from "./pages/Base";

import theme from "./styles/theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollerContextProvider from "./contexts/ScrollerContext";

const Home = React.lazy(() => import("./pages/Home"));
const Forecast = React.lazy(() => import("./pages/Forecast"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const Links = React.lazy(() => import("./pages/LinksPage"));
const Cams = React.lazy(() => import("./pages/CamsPage"));

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ScrollerContextProvider>
          <Base>
            <React.Suspense fallback={<></>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/forecast" element={<Forecast />} />
                <Route path="/links" element={<Links />} />
                <Route path="/cams" element={<Cams />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </React.Suspense>
          </Base>
        </ScrollerContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
