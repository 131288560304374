import { Text, Box, Button, useColorMode } from "@chakra-ui/react";
import { slide as Menu } from "react-burger-menu";
// import { Button } from "react-router-dom";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollerContext } from "../contexts/ScrollerContext";

const SideBar = () => {
  const [menuItems, setMenuItems] = React.useState(null);
  const location = useLocation();
  const { colorMode } = useColorMode();

  const { setScroller } = React.useContext(ScrollerContext);

  const menu_items = {
    current_conditions: ["Current Conditions", "/"],
    tides: ["Tides", "/"],
    sea_temp: ["Sea Temperature", "/"],
    forecast: ["Forecast", "/forecast"],
    cams: ["Cams", "/cams"],
    links: ["Links", "/links"],
  };

  React.useEffect(() => {
    setMenuItems(menu_items);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };
  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  const navigate = useNavigate();
  const handleClick = (dest) => {
    // console.log("DEST", dest);
    const changePage = location.pathname === menu_items[dest][1] ? false : true;
    var delay = 0;
    if (changePage === true) {
      navigate(menu_items[dest][1]);
      delay = 710;
    }
    setTimeout(() => {
      // console.log("wait");
      if (dest === "current_conditions") {
        setScroller("current");
      }
      if (dest === "tides") {
        setScroller("tides");
      }
      if (dest === "sea_temp") {
        setScroller("seatemp");
      }
      if (dest === "forecast") {
        setScroller("forecast");
      }
      if (dest === "cams") {
        setScroller("cams");
      }
      if (dest === "links") {
        setScroller("links");
      }
    }, delay);
  };

  // console.log("MENU", menuItems);

  return (
    <Menu
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
    >
      <Box pt="8">
        <Box mb="0" w="98%" h="3px" bg="black" rounded="xl"></Box>
        {menuItems &&
          Object.keys(menuItems).map((value, index) => {
            return (
              <div key={index}>
                <Box
                  w="100%"
                  rounded="sm"
                  color={colorMode === "light" ? "black" : "white"}
                >
                  <Button
                    w="100%"
                    h="50px"
                    className="side-menu-box"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      const dest = Object.keys(menuItems)[index];
                      handleClick(dest);
                      handleCloseMenu();
                    }}
                  >
                    <Text fontSize="24px">{menuItems[value][0]}</Text>
                  </Button>
                </Box>

                <Box mb="0" w="100%" h="3px" bg="black" rounded="xl"></Box>
              </div>
            );
          })}
      </Box>
    </Menu>
  );
};

export default SideBar;
